import React from 'react'
import Applayout from '../../../components/Layout/Applayout'

const AppNoticia2 = () => {
  return (
        <Applayout>
        <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias em Destaque</h2>
              <p>Tudo o que Você Precisa Saber</p>
            </div>
            <div className="row mt-7">
              <div className="col-lg-5" data-aos="fade-right">
              <h2>2ª Feira Educacional </h2>
              <strong>Notícias </strong>
                <p>Data: 11/06/2019 </p>
                <p>
                A 2ª Feira e Educacional Bendizer foi realizados em vinte e cinco de Outubro de dois mil e vinte sobe o lema: Promoção da Saúde como pilares para o desenvolvimento humano, foram realizadas varias actividades como:
                </p>
                <p>
                Efetuar medidas de Glicenia; Pressão Arterial; Peso e Estatura Orietar Dieta Saúdavel na idade Pré-Escolar e Escolar; Orientar as medidas de prevenção de doenças efecçõezas, de Transmição sexual
                </p>
                <p>
                Nesta feira, vimos projetos inovadores, pesquisas inspiradoras e apresentações que mostram o talento e o trabalho duro de nossos alunos. Cada um dos certificados que serão entregues hoje representa mais do que uma conquista acadêmica – é um reflexo do esforço e da dedicação.
                </p>
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 backanuncio2" style={{height:'500px'}} data-aos="fade-left">
                
              </div>
            </div>
          </div>
        </section>
    </div>
        </Applayout>
          
  )
}

export default AppNoticia2