import React from 'react'
import Applayout from '../../../components/Layout/Applayout'

const Appdesta1 = () => {
  return (
        <Applayout>
        <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias em Destaque</h2>
              <p>Tudo o que Você Precisa Saber</p>
            </div>
            <div className="row mt-7">
              <div className="col-lg-4" data-aos="fade-right">
              <h2> 𝗟𝗔𝗡𝗖̧𝗔𝗠𝗘𝗡𝗧𝗢 E SESSÃO DE AUTÓGRAFOS</h2>
              <strong>Destaques</strong>
                <p>Data: 21 de Março de 2025</p>
                <p>Hora: 11h00</p>
                <p>Local: Instituto Técnico Privado de Saúde Bendizer, Vila Alice, Rua Fernando Pessoa</p>

                <p>
                Companheiros, amigos das coisas de valor, comunidade académica,
                Venho no meu nome e no da editora Jikulumessu Editors Editora Jikulumesu
                </p>

                <p>
                anunciar o Lançamento, Venda e Sessão de Autógrafos do livro "QUESTIONÁRIO GERAL DESCRITIVO DE PROJECTO TECNOLÓGICO: MATERIAL DE PREPARAÇÃO PARA DEFESA DE TRABALHO DE FIM DE CURSO"
                </p>
                <br />
                
                <p >
                Mestre de cerimónia: <strong>Exímio Matamba</strong>
                </p>
                <p> Apresentação do livro: <strong>Mayingi Lukunga</strong></p>
              </div>
              <div className="col-lg-7 mt-3 mt-lg-0 backdestaque2" 
                data-aos="fade-left"
                >
                
              </div>
            </div>
          </div>
        </section>
    </div>
        </Applayout>
          
  )
}

export default Appdesta1