
import React from 'react'
import Applayout from '../../../components/Layout/Applayout'

const Appdestaque1 = () => {
  return (
        <Applayout>
        <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias em Destaque</h2>
              <p>Tudo o que Você Precisa Saber</p>
            </div>
            <div className="row mt-7">
              <div className="col-lg-5" data-aos="fade-right">
              <h2>Aula prática de Enfermagem</h2>
              <strong>Destaques</strong>
                <p>Data: 13/12/2024 </p>
                <p>
                Aula prática de Enfermagem com o Professor Monteiro Moisés com os estudantes da 12ª classe do curso de enfermagem. Breve explicação sobre as vias de administrações de medicamentos<br />
                </p>
                <p>
                Nas aulas práticas de enfermagem, os alunos vivenciam uma série de experiências que são fundamentais para a construção de sua formação técnica e humanística. Essas aulas não se limitam a ensinar apenas técnicas e procedimentos, mas também a proporcionar um contato mais profundo com o universo do cuidado, a ética profissional e o trabalho em equipe.
                <br/>
                </p>
                <p>
                Elas permitem que os alunos desenvolvam habilidades técnicas, emocionais e sociais, fundamentais para o exercício da profissão. A experiência prática ajuda a formar profissionais mais preparados, confiantes e empáticos, aptos a lidar com a complexidade do cuidado de saúde.
                </p>
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 backdestaque1" style={{height:'500px'}} data-aos="fade-left">
                
              </div>
            </div>
          </div>
        </section>
    </div>
        </Applayout>
          
  )
}

export default Appdestaque1