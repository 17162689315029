import React from 'react'
import Applayout from '../../../components/Layout/Applayout'

const AppNoticia3 = () => {
  return (
        <Applayout>
        <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias em Destaque</h2>
              <p>Tudo o que Você Precisa Saber</p>
            </div>
            <div className="row mt-7">
              <div className="col-lg-5" data-aos="fade-right">
              <h2>Feira da Cultura</h2>
              <strong>Notícias </strong>
                <p>Data: 26/04/2020</p>
                <p>
                E a principal temática foi a promoção de hábitos alimentares saudáveis e a educação nutricional. Durante o encontro, professores, apresentaram palestras interativas abordando a importância de uma alimentação equilibrada para a prevenção de doenças crônicas, como diabetes, hipertensão e obesidade
                </p>
                <p>
                O evento incluiu atividades práticas, como oficinas de preparo de receitas saudáveis, onde os participantes aprenderam a criar pratos nutritivos com ingredientes acessíveis.
                </p>
                <p>
                O objetivo principal do evento foi não apenas educar, mas também inspirar os participantes a adotarem práticas alimentares mais saudáveis em sua rotina, além de promover a interação entre profissionais de saúde e a comunidade.
                <p>
                </p>
                </p>
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 backanuncio3" style={{height:'500px'}} data-aos="fade-left">
                
              </div>
            </div>
          </div>
        </section>
    </div>
        </Applayout>
          
  )
}

export default AppNoticia3