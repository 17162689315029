/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Apphome'
import About from './pages/Appabout'
import Precarios from './pages/Appprecarios'
import Destaques from './pages/Appdestaques'
import Laboratory from './pages/Applaboratory'
import Teams from './pages/Appdocentes'
import Estagio from './pages/Appestagios'


import AppNoticia1 from './pages/destaque/Noticia/AppNoticia1';
import AppNoticia2 from './pages/destaque/Noticia/AppNoticia2';
import AppNoticia3 from './pages/destaque/Noticia/AppNoticia3';

import AppEvento1 from './pages/destaque/Evento/AppEvento1';
import AppEvento2 from './pages/destaque/Evento/AppEvento2';
import AppEvento3 from './pages/destaque/Evento/AppEvento3';

import AppDestaque1 from './pages/destaque/Destaque/Appdestaque1';
import Appdesta1 from './pages/destaque/Destaque/Appdestaque2 ';
import AppDestaque3 from './pages/destaque/Destaque/Appdestaque3';



function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/sobre' element={<About />} />
        <Route path='/preços' element={<Precarios />} />
        <Route path='/destaques' element={<Destaques />} />
        <Route path='/laboratorio' element={<Laboratory />} />
        <Route path='/docentes' element={<Teams />} />
        <Route path='/estagio' element={<Estagio />} />

        <Route path='/destaqueestudantes' element={<AppNoticia1 />} />
        <Route path='/destaqueafrica' element={<AppNoticia2 />} />
        <Route path='/destaqueactividade' element={<AppNoticia3 />} />

        <Route path='/destaquealunos' element={<AppEvento1 />} />
        <Route path='/destaquecultura' element={<AppEvento2 />} />
        <Route path='/destaquesaude' element={<AppEvento3 />} />

        <Route path='/destaqueaula' element={<AppDestaque1 />} />
        <Route path='/destaquepremio' element={<Appdesta1 />} />
        <Route path='/destaquenoticia' element={<AppDestaque3 />} />




        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
