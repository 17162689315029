import React from 'react'
import Applayout from '../../../components/Layout/Applayout'

const AppEvento1 = () => {
  return (

        <Applayout>
        <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias em Destaque</h2>
              <p>Tudo o que Você Precisa Saber</p>
            </div>
            <div className="row mt-7">
              <div className="col-lg-5" data-aos="fade-right">
              <h2>Estudantes qualificados</h2>
              <strong>Eventos </strong>
                <p>Data: 10/08/2019 </p>
                <p>
                Os estudantes qualificados de nossa instituição são indivíduos comprometidos com a excelência acadêmica e o desenvolvimento pessoal. <br /><br />Eles se destacam por sua curiosidade intelectual, capacidade de pensamento crítico e habilidade de trabalhar em equipe. Com uma mentalidade proativa, buscam constantemente novos desafios e oportunidades de aprendizado.<br />
                </p>
                <p>
                Nossos alunos demonstram responsabilidade social e ética, participando ativamente de projetos comunitários e iniciativas que promovem a inclusão e a sustentabilidade. <br/><br/>
                </p>
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 backEventos1" style={{height:'500px'}} data-aos="fade-left">
              </div>
            </div>
          </div>
        </section>
    </div>
        </Applayout>
          
  )
}

export default AppEvento1
