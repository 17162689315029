/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react'

function Appsectioncontact() {
  return (
    <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Contato</h2>
              <p>Contate-nos</p>
            </div>
            <div className="row mt-5">
              <div className="col-lg-4" data-aos="fade-right">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt" />
                    <h4>Localização:</h4>
                    <p>Estamos localizados na Vila-Alice M. Rangel 
                    Rua. Fernando Pessoa Trav. Alberto Correia </p>
                  </div>
                  <div className="email">
                    <i className="bi bi-envelope" />
                    <h4>Email:</h4>
                    <p>secretaria@itpsbendizer.co.ao</p>
                  </div>
                  <div className="phone">
                    <i className="bi bi-phone" />
                    <h4>Números de atendimento:</h4>
                    <p> 949748612, 945380685</p>
                    <p> 949748594, 938326467</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 mt-7 mt-lg-0 backcontact" data-aos="fade-left">
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default Appsectioncontact