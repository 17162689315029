import React from 'react'
import Applayout from '../../../components/Layout/Applayout'

const AppEvento3 = () => {
  return (

        <Applayout>
        <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias em Destaque</h2>
              <p>Tudo o que Você Precisa Saber</p>
            </div>
            <div className="row mt-7">
              <div className="col-lg-5" data-aos="fade-right">
              <h2>Actividade da lavagem das mãos</h2>
              <strong>Eventos </strong>
                <p>Data: 11/03/2024</p>
                <p>
                Na escola de saúde, a atividade de lavagem das mãos é frequentemente realizada em forma de treinamentos práticos, oficinas ou campanhas educativas. O objetivo principal dessas atividades é sensibilizar os alunos para a importância da higiene das mãos e garantir que eles adquiram a habilidade de realizar essa prática de forma correta e eficaz.
                </p>
                <h4>Recomendações</h4>
                <p>
                Durante uma dessas atividades, os alunos são orientados sobre os momentos críticos em que a lavagem das mãos deve ser realizada: antes e após o atendimento ao paciente, antes de realizar procedimentos invasivos, após o contato com fluidos corporais ou superfícies contaminadas e depois de manusear equipamentos médicos..
                </p>
                <h4>Responsábilidade</h4>
                <p>
                A realização de atividades sobre a lavagem das mãos em uma escola de saúde vai além do simples aprendizado de uma técnica. 
                <br />
                Ao enfatizar a lavagem das mãos desde o início da formação, as escolas de saúde desempenham um papel crucial na promoção da saúde pública e na redução das infecções nosocomiais.
                </p>     
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 backEventos3" style={{height:'500px'}} data-aos="fade-left">
              </div>
            </div>
          </div>
        </section>
    </div>
        </Applayout>
          
  )
}

export default AppEvento3