import React from 'react'
import Applayout from '../../../components/Layout/Applayout'

const Appdestaque3 = () => {
  return (
        <Applayout>
        <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias em Destaque</h2>
              <p>Tudo o que Você Precisa Saber</p>
            </div>
            <div className="row mt-7">
              <div className="col-lg-5" data-aos="fade-right">
              <h2>Casos de cólera em Angola</h2>
              <strong>Destaques</strong>
                <p>Data: 16/02/2025</p>
                <p>
                Desde o início do surto, já foram registrados 5.075 casos em 11 províncias, afetando pessoas entre 2 e 100 anos. Do total, 56% são do sexo masculino (2.861 casos) e 44% do sexo feminino (2.214 casos).
                </p>
                <p>
                O surto de cólera que se iniciou na província de Luanda, com epicentro no bairro do Paraíso, município do Cacuaco, com um total de 94 casos e seis óbitos, estendeu-se esta semana às províncias do Bengo (12 casos) e do Icolo e Bengo (9 casos, 1 óbito).
                "Dada a rápida progressão de casos e mortes, existe um alto risco de expansão do surto a todo o país, especialmente em áreas densamente povoadas com acesso limitado à água potável e fraco saneamento", referiu o Ministério da Saúde angolano num comunicado.
                O surto foi agravado pela precipitação das últimas semanas, uma "situação que poderá agravar-se ainda mais, com a chegada da época de chuvas mais intensas no mês de março e abril".
                </p>
               
                <p>
                Estão em processamento no laboratório nacional de referência, 16 amostras de casos suspeitos.
                Os 224 casos notificados referem-se a pessoas com idades compreendidas entre 2 e 70 anos, dos quais 103 são do sexo masculino e 121 do sexo feminino.
                O Governo angolano fez hoje um apelo à população para ter cuidados preventivos face ao aumento dos casos de cólera, que se podem agravar com as chuvas dos próximos meses.
                </p>

                <p>
                A cólera "é uma doença grave e contagiosa transmitida pelo consumo de água ou alimentos contaminados pelo micróbio da doença e pode levar à morte se a pessoa doente não for tratada rapidamente", alerta o Ministério, que recomenda aos angolanos comportamentos preventivos.
                Lavar frequentemente as mãos, tratar a água potável com gotas de lixívia, ferver a água, lavar a comida crua com água fervida ou tratada com lixívia ou desinfetar as casas de banho são algumas das recomendações das autoridades de saúde.
                </p>
                <p>
                Nos casos suspeitos, deve-se "dar muitos líquidos ou um soro caseiro", com água fervida, açúcar e sal, antes de transportar o doente para uma unidade de saúde.
                </p>
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 backdestaque3" style={{height:'500px'}} data-aos="fade-left">
                
              </div>
            </div>
          </div>
        </section>
    </div>
        </Applayout>
          
  )
}

export default Appdestaque3