import React from 'react'
import Applayout from '../../../components/Layout/Applayout'

const AppNoticia1 = () => {
  return (

        <Applayout>
        <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias em Destaque</h2>
              <p>Tudo o que Você Precisa Saber</p>
            </div>
            <div className="row mt-7">
              <div className="col-lg-5" data-aos="fade-right">
              <h2>Finalistas do Ano Lectivo 2023/24</h2>
              <strong>Notícias </strong>
                <p>Ano Lectivo 2023/24</p>
                <p>
                Os alunos que concluíram seus cursos em 2023 em um instituto de saúde estão mais preparados para lidar com os novos desafios do setor, com uma visão mais ampla e integrada da saúde, que inclui não apenas os aspectos clínicos, mas também o apoio psicológico, a tecnologia e a saúde pública.<br />
                </p>
                <p>
                Esses futuros profissionais terão um papel crucial na reconstrução e no fortalecimento dos sistemas de saúde, especialmente à medida que a sociedade enfrenta novas crises de saúde, como doenças crônicas, saúde mental e emergências sanitárias globais.
                </p>
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 backanuncio1" style={{height:'500px'}} data-aos="fade-left">
                
              </div>
            </div>
          </div>
        </section>
    </div>
        </Applayout>
          
  )
}

export default AppNoticia1
