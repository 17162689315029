import React from 'react'
import Applayout from '../../../components/Layout/Applayout'

const AppEvento2= () => {
  return (

        <Applayout>
        <div>
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias em Destaque</h2>
              <p>Tudo o que Você Precisa Saber</p>
            </div>
            <div className="row mt-7">
              <div className="col-lg-5" data-aos="fade-right">
              <h2>Atividade Cultural</h2>
              <strong>Eventos </strong>
                <p>Data: 05/04/2019</p>
                <p>
                A abertura da atividade foi marcada por uma apresentação especial no auditório da escola. A diretora, Sra. Mariana Costa, fez um discurso caloroso destacando a importância da cultura e das artes na formação dos alunos.<br /><br /> Em seguida, os alunos da turma de Teatro apresentaram uma peça inspirada em histórias folclóricas locais, que encantou a plateia com sua energia e talento.
                </p>
                <p>
                Ao longo do dia, diversos estandes foram montados no pátio da escola, cada um representando uma expressão cultural diferente. Havia uma área dedicada à música, onde os alunos mostraram suas habilidades em diversos instrumentos.
                </p>
                    
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 backEventos2" style={{height:'500px'}} data-aos="fade-left">
              </div>
            </div>
          </div>
        </section>
    </div>
        </Applayout>
          
  )
}

export default AppEvento2
